import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
const InterstitialLayout = dynamic(() => import("../Mixture/View/InterstitialLayout"));
import { CampaignIframeOverlayConstants } from "../../adapters/helpers/Constants"; 

const InterstitialSignUpBlock = ( props ) => {
    const { document: doc } = props;

    const expiryHours = doc?.fields?.interstitialBlock?.fields?.expiryHours

    let interstitialPopupTriggerInterval;

    const [openInterstitial, setOpenInterstitial] = useState( false );
    const [interstitialPopupTriggerInit, setInterstitialPopupTriggerTimer] = useState(false);
    
    const interestitialEventPush = (eventCategory, eventAction, eventLabel) => {
        window.dataLayer.push({
            'event': CampaignIframeOverlayConstants?.customEvent,
            'GAeventCategory': eventCategory,
            'GAeventAction': eventAction,
            'GAeventLabel': eventLabel
        });
    };

    const setInterstitialOpenTimer = () =>{
        setTimeout(()=>{
            setOpenInterstitial(true)
            const date = new Date();
            date.setHours(parseInt(expiryHours)); // for one day validaity
            const expires = `expires=${date.toUTCString()}`;
            document.cookie = `${CampaignIframeOverlayConstants.disableIntersistialPopUpCookie}=ok; ${expires}; path=/`;
            interestitialEventPush(CampaignIframeOverlayConstants?.eventCategory, CampaignIframeOverlayConstants?.startSurveyEvent, CampaignIframeOverlayConstants?.eventLabel);
        },7000)
    }

    const interstitialPopupTriggerTimer = () => {
        const cookieConsentAcceptDetectCookieValue = window?.dataLayer?.filter(dataLayerItem => dataLayerItem?.event === CampaignIframeOverlayConstants.cookieConsentAcceptDetectKeyName);
        if (!interstitialPopupTriggerInit && cookieConsentAcceptDetectCookieValue?.length > 0 && cookieConsentAcceptDetectCookieValue?.[1]) {
            setInterstitialOpenTimer()
            setInterstitialPopupTriggerTimer(true);
            stopInterstitialPopupTriggerTimer();
        }
    };

    const stopInterstitialPopupTriggerTimer = () => {
        interstitialPopupTriggerInterval && clearInterval(interstitialPopupTriggerInterval);
    };

    useEffect( () => {
        if (document.cookie.includes(`${CampaignIframeOverlayConstants.interstitialRegistered}=ok`)) {
            setOpenInterstitial( false );
        } else {
            interstitialPopupTriggerInterval = setInterval(interstitialPopupTriggerTimer, CampaignIframeOverlayConstants.interstitialPopupTriggerTimerDelay);
        }
    },[])

    return (
        openInterstitial && <div className='ob-interstitial-button'>
            <InterstitialLayout document={doc?.fields?.interstitialBlock} setOpenInterstitial={setOpenInterstitial} />
        </div>
    )
}

export default InterstitialSignUpBlock;